<template>
    <div id="pageTable">
        <v-container 
            class="d-flex align-center justify-center" 
            style="height: 80vh; width: 80vw;"
            v-if="rol != 'root' && rol != 'admin' && rol != 'admin-empresa'"
        >
			<v-card class="" style="border-radius:20px" max-width="500">
                <v-card-title class="justify-center card_titulo">
                    <div class="headerModal">
                        <img :src="'/static/icon/peligro.svg'" class="imgModal"/>
                    </div>
                </v-card-title>
                <v-card-text class="titleModal text-center">
                    Esta vista no esta disponible para este rol de usuario.
                </v-card-text>
                <v-card-actions class="d-flex align-center justify-center card_accion">
                    <v-btn
                        class="btnGuardar"
                        @click="redireccionar()"
                    >
                        Ok
                    </v-btn>
                </v-card-actions>
            </v-card>
		</v-container>        
        <v-container v-else xl fluid>
            <v-row>
                <v-overlay :value="overlay" z-index="999">
                    <div class="align-center justify-center text-center">
                        <v-progress-circular
                            indeterminate
                            :size="100"
                            :width="7"
                            color="#2DB9D1"
                        >
                            <v-img
                                src="/static/icon/favicon.ico"
                                :width="60"
                            ></v-img>
                        </v-progress-circular>
                        <p class="mt-5">{{fraseOverlay}}</p>
                    </div>
                    
                </v-overlay>
                <v-col cols="12" lg="12">
                    <data-table
                        ref="tablaContratos"
                        :tableName="'Contratos'"
                        :url="url"
                        :columns="columns"
                        :filters="filters"
                        @loadModalData="loadModalData"
                        @setFilters="setFilters"
                        :perPage="[10, 25, 50, 100]"
                        :showAdvancedFilters="true"
                        :isSelectable="true"
                        :unselected="unselected_general"
                        @selectAllEvent="selectAll"
                        :disabledCheck="disabledCheck"
                    >
                        <template slot="actionButtonsLeft">
                            <BtnAcciones
                                :btnNombre="'Acciones'"
                                :btnColor="'color-58C5D8'"
                            >
                                <template v-slot:opciones>
                                    <div
                                        @click="!masivo ? '#':abrirModalRenovar(null, 'renovarMasivo')"
                                        class="py-2"
                                        :class="computedClass"
                                    >
                                        <span>Renovar contrato</span>
                                    </div>
                                    <div 
                                        @click="!masivo ? '#':regenerarContratoMasivo()" 
                                        :style="!masivo? 'cursor:not-allowed;':''" 
                                        class="btnAccionesItem py-2"
                                        :class="[!masivo ? 'deshabilitado':'']"
                                    >
                                        <span>Volver a generar</span>
                                    </div>
                                    <div 
                                        @click="!masivo ? '#':descargarMasivo()" 
                                        :style="!masivo? 'cursor:not-allowed;':''" 
                                        class="btnAccionesItem py-2"
                                        :class="[!masivo ? 'deshabilitado':'']"
                                    >
                                        <span>Descargar seleccionados</span>
                                    </div>
                                    <div 
                                        @click="abrirModalReporte()" 
                                        class="btnAccionesItem py-2"
                                    >
                                        <span>Descargar reporte de contratos</span>
                                    </div>
                                </template>
                            </BtnAcciones>
                        </template>

                        <template slot="filters">
                            <template v-if="rol == 'root'">
                                <v-col xs="12" sm="12" md="12" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="clientes"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Cliente"
                                    persistent-hint
                                    v-model="cliente_value"
                                ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin'">
                                <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="empresas"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Empresa"
                                    persistent-hint
                                    v-model="empresa_value"
                                ></v-autocomplete>
                                </v-col>
                            </template>

                            <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                <v-col cols="12" xs="12" :sm="rol == 'admin-empresa'?'12':'6'" :md="rol == 'admin-empresa'?'12':'6'" class="py-0">
                                    <v-autocomplete
                                        outlined
                                        :items="sucursales"
                                        item-text="nombre"
                                        item-value="id"
                                        label="Sucursal"
                                        persistent-hint
                                        v-model="sucursal_value"
                                    ></v-autocomplete>
                                </v-col>
                            </template>
                            
                            <v-col cols="12" xs="12" sm="12" md="12" class="py-0" >
                                <v-text-field
                                outlined
                                label="Nombre"
                                v-model="nombre"
                                ></v-text-field>
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    label="Tipo Contrato"
                                    :items="tipoContratosBusqueda"
                                    item-text="claveDescripcion"
                                    item-value="id"
                                    persistent-hint
                                    v-model="tipo_contrato_value"
                                    class="pa-0 ma-0"
                                    id="txtTipoContrato"
                                >
                                </v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="contratos_estatus"
                                    item-text="nombre"
                                    item-value="clave"
                                    label="Estatus"
                                    persistent-hint
                                    v-model="contrato_estatus_value"
                                ></v-autocomplete>
                            </v-col>

                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                <v-select
                                    outlined
                                    :items="itemsTipoEmpleado"
                                    item-text="descripcion"
                                    item-value="valor"
                                    label="Tipo de empleado"
                                    persistent-hint
                                    v-model="valorTipoEmpleado"
                                ></v-select>
                            </v-col>

                            <v-col xs="12" sm="6" md="6" class="py-0">
                                <v-autocomplete
                                    outlined
                                    :items="itemsRegistrosPatronales"
                                    item-text="nombre"
                                    item-value="id"
                                    label="Registro patronal"
                                    persistent-hint
                                    v-model="registro_patronal_value"
                                ></v-autocomplete>
                            </v-col>
                        </template>
                        
                        <tbody slot="body" slot-scope="{ data }">
                            <template v-for="(item,index) in data">
                                <expandable-item 
                                    :item="item"
                                    :selected_general="selected_general" 
                                    :key="index" 
                                    @abrirModalPDF="abrirModalPDF" 
                                    @descargar="descargar" 
                                    @abrirModalRenovar="e => abrirModalRenovar(e, 'renovarIndividual')"
                                    @regenerarContrato="e => regenerarContrato(e)"
                                    @eliminar="eliminar"
                                    @eliminarDocumento="eliminarDocumento"
                                    @abrirModalCargaDocumento="abrirModalCargaDocumento"
                                    @selectEmployee="selectEmployee"
                                    :disabledCheck="disabledCheck"
                                >
                                </expandable-item>
                                
                            </template>
                        </tbody>
                    </data-table>
                </v-col>
            </v-row>

            <template >
                <v-dialog v-model="dialogRenovar" scrollable persistent width="600px" class="borde-card">
                    <v-card class="borde-card">
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <img :src="imagen" class="imgModal" />
                                    <h2 class="titleModal">{{ titulo_modal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <v-container grid-list-md id="contenedor" class="pt-0">
                                    <v-divider class="divider"></v-divider>
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0">
                                        <ValidationProvider v-slot="{ errors }" name="Tipo Contrato" rules="required">
                                            <v-autocomplete
                                                outlined
                                                label="Tipo Contrato"
                                                :items="tipoContratos"
                                                item-text="claveDescripcion"
                                                item-value="id"
                                                persistent-hint
                                                v-model="renovacion.tipo_contrato_id"
                                                class="pa-0 ma-0"
                                                required 
                                                :error-messages="errors" 
                                                id="txtTipoContrato"
                                            >
                                            </v-autocomplete>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col v-if="determinado" xs="12" sm="12" md="12" class="pa-0 ma-0 mt-3 radio_group_01 radio-contrato" >
                                        <v-radio-group v-model="renovacion.dias">
                                            <template v-slot:label>
                                                <div class="mb-4">Elegir nueva vigencia:</div>
                                            </template>
                                            <v-radio class="mb-4 radio-label" label="30 días" value="30"></v-radio>
                                            <v-radio class="mb-4 radio-label" label="60 días" value="60"></v-radio>
                                            <v-radio class="mb-4 radio-label" label="90 días" value="90"></v-radio>
                                            <v-radio class="mb-4 radio-label" label="180 días" value="180"></v-radio>
                                        </v-radio-group>
                                    </v-col>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button
                                    class="btnCerrar"
                                    @click="cerrarModal()" 
                                    :disabled="isSaving"
                                >
                                    Cerrar
                                </button>
                                <template
                                    v-if="tipoAccion == 'renovarMasivo' ||  tipoAccion == 'renovarIndividual'"
                                >
                                    <v-btn
                                        class="btnGuardar"
                                        @click="masivo?renovarContratoMasivo():renovarContrato()" 
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                    >
                                        Aceptar
                                    </v-btn>
                                </template>
                                <template
                                    v-if="tipoAccion == 'regenerarMasivo' ||  tipoAccion == 'regenerarIndividual'"
                                >
                                    <v-btn
                                        class="btnGuardar"
                                        @click="masivo?regenerarContratoMasivo():regenerarContrato()" 
                                        :disabled="invalid" 
                                        :loading="isSaving"
                                    >
                                        Aceptar
                                    </v-btn>
                                </template>
                                
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template>
                <v-dialog v-model="dialog_pdf" persistent width="720px">
                    <v-card>
                        <v-card-title>
                            <div class="col-lg-12 pa-0">
                                <v-btn
                                    @click="cerrarModal()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar modal-pull-right"
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            </div>
                            <span class="titleModal ml-4" v-text="titulo_modal"></span>
                        </v-card-title>
                        <v-card-text class="px-0 pb-0">
                            <v-container grid-list-md>
                                <v-row>
                                    <div id="padre" v-if="!procesoFinalizado" class="ma-5">
                                        <div id="loading">
                                            <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                        </div>
                                    </div>
                                    <template>
                                        <v-col v-show="procesoFinalizado" cols="12"  xs="12" sm="12" md="12">
                                            <iframe src="" id="myIframe"  width='100%' height='460px' allowfullscreen webkitallowfullscreen></iframe>
                                        </v-col>
                                    </template>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
            <template>
                <v-dialog v-model="dialogCargaDocumento" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal" id="btnModalCargaDocumento"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="form" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <h2 class="titleModal">{{ titulo_modal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <v-container grid-list-md id="contenedor" class="pt-0">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <v-col cols="12" xs="12" sm="12" md="12" lg="12">
                                            <Upload
                                                :tamanioArchivo="10485760" 
                                                :multiple="false"
                                                :extensionInput="'.pdf'"
                                                :extensionDrop="'.pdf'"
                                                :arrayFile="archivo.archivo"
                                                @vModelFile="(data)=> archivo.archivo = data"
                                            >
                                            </Upload>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                <v-btn class="btnGuardar" @click="guardarDocumento()" :disabled="invalid" :loading="isSaving">Subir</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template>
                <v-dialog v-model="dialogReporte" persistent max-width="500px">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" style="display: none;" class="botonModal"></v-btn>
                    </template>
                    <v-card>
                        <ValidationObserver ref="formReporte" v-slot="{ invalid }">
                            <v-card-title class="justify-center card_titulo">
                                <div class="headerModal">
                                    <h2 class="titleModal">{{ titulo_modal }}</h2>
                                </div>
                            </v-card-title>
                            <v-card-text class="card_texto">
                                <v-container grid-list-md id="contenedor" class="pt-0">
                                    <v-divider class="divider"></v-divider>
                                    <v-row>
                                        <template v-if="rol == 'root'">
                                            <v-col xs="12" sm="12" md="12" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    :items="clientes"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Cliente"
                                                    persistent-hint
                                                    v-model="cliente_value"
                                                ></v-autocomplete>
                                            </v-col>
                                        </template>

                                        <template v-if="rol == 'root' || rol == 'admin'">
                                            <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    :items="empresas"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Empresa"
                                                    persistent-hint
                                                    v-model="empresa_value"
                                                ></v-autocomplete>
                                            </v-col>
                                        </template>

                                        <template v-if="rol == 'root' || rol == 'admin' || rol == 'admin-empresa'">
                                            <v-col cols="12" xs="12" :sm="rol == 'admin-empresa'?'12':'6'" :md="rol == 'admin-empresa'?'12':'6'" class="py-0">
                                                <v-autocomplete
                                                    outlined
                                                    :items="sucursales"
                                                    item-text="nombre"
                                                    item-value="id"
                                                    label="Sucursal"
                                                    persistent-hint
                                                    v-model="sucursal_value"
                                                ></v-autocomplete>
                                            </v-col>
                                        </template>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                :items="contratos_estatus"
                                                item-text="nombre"
                                                item-value="clave"
                                                label="Estatus"
                                                persistent-hint
                                                v-model="valorEstatus"
                                            ></v-autocomplete>
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <v-select
                                                outlined
                                                :items="itemsTipoEmpleadoReporte"
                                                item-text="descripcion"
                                                item-value="valor"
                                                label="Tipo de empleado"
                                                persistent-hint
                                                v-model="valorTipoEmpleadoReporte"
                                            ></v-select>
                                        </v-col>
                                        <v-col xs="12" sm="12" md="12">
                                            <div class="RangoFecha">
                                                <span>Rango de inicio de contratos</span>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                label="Fecha inicio"
                                                :valorDefecto="fechaInicio"
                                                v-model="fechaInicio"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaInicio"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                label="Fecha fin"
                                                :valorDefecto="fechaFin"
                                                v-model="fechaFin"
                                                placeholder="dd/mm/aaaa"
                                                ref="FechaFin"
                                            />
                                        </v-col>
                                        <v-col xs="12" sm="12" md="12">
                                            <div class="RangoFecha">
                                                <span>Rango de fin de contratos</span>
                                            </div>
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                label="Fecha inicio"
                                                :valorDefecto="rangoFechaFin_Inicio"
                                                v-model="rangoFechaFin_Inicio"
                                                placeholder="dd/mm/aaaa"
                                                ref="rangoFechaFin_Inicio"
                                            />
                                        </v-col>
                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <VuetifyDatePicker
                                                outlined
                                                label="Fecha fin"
                                                :valorDefecto="rangoFechaFin_Fin"
                                                v-model="rangoFechaFin_Fin"
                                                placeholder="dd/mm/aaaa"
                                                ref="rangoFechaFin_Fin"
                                            />
                                        </v-col>

                                        <v-col cols="12" xs="12" sm="6" md="6" class="py-0">
                                            <v-select
                                                outlined
                                                :items="itemsTipoReporte"
                                                item-text="descripcion"
                                                item-value="valor"
                                                label="Tipo de reporte"
                                                persistent-hint
                                                v-model="tipoReporte"
                                            ></v-select>
                                        </v-col>

                                        <v-col xs="12" sm="6" md="6" class="py-0">
                                            <v-autocomplete
                                                outlined
                                                :items="itemsRegistrosPatronales"
                                                item-text="nombre"
                                                item-value="id"
                                                label="Registro patronal"
                                                persistent-hint
                                                v-model="registro_patronal_value"
                                            ></v-autocomplete>
                                        </v-col>

                                    </v-row>
                                </v-container>
                            </v-card-text>
                            <v-card-actions class="d-flex justify-end card_accion">
                                <button class="btnCerrar" @click="cerrarModal()" :disabled="isSaving">Cerrar</button>
                                <v-btn class="btnGuardar" @click="descargarReporte()" :disabled="invalid" :loading="isSaving">Descargar</v-btn>
                            </v-card-actions>
                        </ValidationObserver>
                    </v-card>
                </v-dialog>
            </template>

            <template class="borde-card">
                <v-dialog v-model="dialogErrores" persistent max-width="950px" class="borde-card">
                    <template v-slot:activator="{ on }">
                        <v-btn v-on="on" class="botonModal" id="btnModalErrores"></v-btn>
                    </template>
                    <v-card class="borde-card">
                        <v-card-title class="padding_izquierdo padding_derecho padding_arriba">
                                <v-btn
                                    @click="cerrarModalErrores()"
                                    small
                                    slot="activator"
                                    icon
                                    text
                                    class="v-btn-cerrar modal-pull-right"
                                    absolute
                                    top
                                    right
                                >
                                    <v-icon class="icono-cerrar"></v-icon>
                                </v-btn>
                            <div class="headerModalStart">
                                <h2 class="titleModalErrores">Errores</h2>
                            </div>
                        </v-card-title>

                        <v-card-text class="padding_izquierdo padding_derecho padding_abajo">
                            <div id="padre" v-show="isLoadingError">
                                <div id="loading">
                                    <v-progress-circular indeterminate :size="100" :width="7" color="blue"></v-progress-circular> 
                                </div>
                            </div>
                            
                            <v-container v-show="!isLoadingError" grid-list-md id="contenedor">
                                <v-row class="pa-0 ma-0">
                                    <v-col cols="12" xs="12" sm="12" md="12" class="pa-0 ma-0">
                                        <table class="tbl-errores">
                                            <thead class="tbl-header">
                                                <tr>
                                                    <th style="border-top-left-radius: 20px;" class="pl-7 errorth">Empleado</th>
                                                    <th class="errorth">Tipo de contrato</th>
                                                    <th style="border-top-right-radius: 20px;" class="errorth">Error</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-if="erroresModal.length == 0" >
                                                    <td class="emptyTableErrores" colspan="2">
                                                        No hay elementos para mostrar
                                                    </td>
                                                </tr>
                                                <template v-else v-for="(error, index) in erroresModal">
                                                    <template>
                                                        <tr :key="index">
                                                            <td class="pl-7 errortd pt-4 pb-0">
                                                                <div class="textoTablaError01">{{ error.empleado }}</div>
                                                            </td>
                                                            <td class="errortd"> <div class="textoTablaError02">{{error.tipo_contrato}}</div> </td>
                                                            <td class="errortd"> <div class="textoTablaError03">{{error.error}}</div> </td>
                                                        </tr>
                                                        <tr :key="'A'+index" class="trDivider"><div class="my-2"></div></tr>
                                                    </template>
                                                </template>
                                            </tbody>
                                        </table>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </template>
        </v-container>
    </div>
</template>

<script>

//IMPORTS

//APIS

import apiTipoContratoSAT from '@/api/nominas/tipoContrato';
import clientesApi from "@/api/clientes";
import empresaApi from "@/api/empresas";
import sucursalApi from "@/api/sucursales";
import departamentoApi from "@/api/departamentos";
import queries from '@/queries/Contratos';
import contratosApi from "@/api/contratos";
import documentoApi from "../api/documentos";
import apiRegistroPatronal from "@/api/nominas/registroPatronal";

//COMPONENTS

import Datatable from '@/components/datatable/Datatable';
import VuetifyDatePicker from '@/components/VuetifyDatePicker.vue';
import ExpandableItem from '@/components/ExpandableItemContrats.vue';
import Upload from "@/components/DragAndDrop.vue";
import BtnAcciones from '@/components/BtnAcciones.vue';

//PLUGINS

import Notify from '@/plugins/notify';
import GetUrlImg from "@/plugins/Urlimagen";
import gFunctions from '@/js/global_functions';

export default {
    components: {
        VuetifyDatePicker,
        'data-table': Datatable,
        ExpandableItem,
        Upload,
        BtnAcciones,
    },
    data() {
        return {
            keyFile                 : 0,
            accion                  : null,
            datosLogin              : null,
            rol                     : null,
            cliente_value           : null,
            empresa_value           : null,
            sucursal_value          : null,
            nombre                  : null,
            tipo_contrato_value     : null,
            contrato_estatus_value  : null,

            dialog_pdf              : false,
            dialogCargaDocumento    : false,
            dialogRenovar           : false,
            procesoFinalizado       : false,
            isSaving                : false,
            overlay                 : false,
            masivo                  : false,
            checkAll                : false,
            determinado             : false,

            titulo_modal    : "",
            url             : "contratos/find",
            imagen          : "/static/modal/contrato.png",
            fraseOverlay    : "Descargando",

            clientes                : [],
            empresas                : [],
            sucursales              : [],
            departamentos           : [],
            registroPatronal        : [],
            tipoContratos           : [],
            selected_general        : [],
            unselected_general      : [],
            contratos_estatus       : [],
            columns                 : [
                {
                    label: "Empleado",
                    name: "empleado",
                    filterable: true,
                },
                {
                    label: "Tipo de contrato",
                    name: "tipo_contrato",
                    filterable: false,
                },
                {
                    label: "Vigencia",
                    name: "vigencia",
                    filterable: false,
                },
                {
                    label: "Estatus",
                    name: "estatus",
                    filterable: false,
                },
                {
                    label: "Opciones",
                    name: "opciones",
                    align: 'center',
                    filterable: false,
                },
                {
                    label: "",
                    name: "desplegar",
                    align: 'center',
                    width:"8",
                    filterable: false,
                },
            ],

            filters         : {
            },
            renovacion      : {
                value : null,
                contrato_empleado_id : null,
                tipo_contrato_id : null,
                registro_patronal_id : null,
            },
            archivo         : {
                archivo                 : [],
                cliente_id              : null,
                empresa_id              : null,
                sucursal_id             : null,
                departamento_id         : null,
                empleado_id             : null,
                documento_id            : null,
                url                     : null,
                extension               : null,
                nombre                  : null,
                contrato_empleado_id    : null
            },

            tipoContratosBusqueda   : [],
            dialogReporte           : false,
            valorEstatus            : null,
            itemsTipoEmpleado       : [
                {
                    descripcion : "Empleados activos",
                    valor       : true
                },
                {
                    descripcion : "Empleados de baja",
                    valor       : false
                }
            ],
            valorTipoEmpleado   : true,
            tipoAccion          : null,

            dialogErrores       : false,
            isLoadingError      : false,
            erroresModal        : [],
            disabledCheck       : false,
            fechaInicio         : null,
            fechaFin            : null,
            rangoFechaFin_Inicio: null,
            rangoFechaFin_Fin   : null,
            tipoReporte         : 'HISTORICO',
            itemsTipoReporte    : [
                {
                    descripcion : "Historico de contratos",
                    valor       : "HISTORICO"
                },
                {
                    descripcion : "Contratos actuales",
                    valor       : "actual"
                },
            ],

            itemsTipoEmpleadoReporte : [
                {
                    descripcion : "Empleados (activos-bajas)",
                    valor       : "todos"
                },
                {
                    descripcion : "Empleados activos",
                    valor       : "activos"
                },
                {
                    descripcion : "Empleados de baja",
                    valor       : "inactivos"
                }
            ],
            valorTipoEmpleadoReporte   : "todos",

            itemsRegistrosPatronales    : [],
            registro_patronal_value     : null,
        }
    },
    /* WATCH 
    Entrada: valor nuevo de la variable y valor pasado de la variable (newValue,oldValue)
    Salida:
    Descripcion: Las funciones de este tipo reciben el valor 
                 nuevo y pasado de una variable cuando detecta 
                 un cambio para posteriormente ejecutar alguna 
                 instruccion.*/
    watch: {
        /*Descripcion: Activa o desactivo los botones de 
                       accion masiva*/
        selected_general:function(value) {
            if(value.length > 0 ){
                this.masivo =  true;
            }
            else{
                this.masivo =  false;
            }
        },
        /*Descripcion: Ejectuta cambiaCliente() mandando
                       el nuevo valor de esta variable*/
        cliente_value: function(val) {
            this.cambiaCliente(val);
            this.getRegistrosPatronales(val);
            this.getContratosFormatosBusqueda();
        },
        /*Descripcion: Ejectuta cambiaEmpresa() mandando
                       el nuevo valor de esta variable*/
        empresa_value: function(val) {  
            this.cambiaEmpresa(val);
        },
        /*Descripcion: Muestra o quita los radio butons de dias
                       segun el tipo de contrato*/
        'renovacion.tipo_contrato_id' : function(val){
            console.log(val)
            if(val != null){
                let contrato = this.tipoContratos.find(element => element.id == val);
                if(contrato.clave_formato == "03"){
                    this.determinado = true;
                    this.renovacion.dias = "30";
                }else{
                    this.determinado = false;
                    this.renovacion.dias = null;
                } 
            }
            
        },
        /*Descripcion: ejecuta la buqueda de contraotos formatos cuando
                        haya uyn cambio en el rp*/
        'renovacion.registro_patronal_id' : function(val){
            console.log(val);
            if(val !== null){
                console.log('cambio: ', val);
                // this.getContratosFormatos()
            }
        },

        /* 'registro_patronal_value' : function(val){
            if(val !== null){
                console.log('cambio: ', val);
                //this.$apollo.queries.contratosFormatos.refetch()
            }
        } */
    },
    computed:{

        computedClass: function(){
            if(!this.masivo || this.renovacion.registro_patronal_id == null){
                return 'deshabilitado'
            }

            return 'btnAccionesItem'
        }
    },
    methods: {
        //FUNCIONES GENERALES

        /*
        Entrada:
        Salida:
        Descripción: Se ejectua desde el crate para poder ver 
                     el tipo del rol del usuario loguado y en base a eso 
                     realizar validaciones por rol ademas de ejecutar las 
                     funcinones para el funcionamiento inicial del modulo.
        */
        init() {
            this.datosLogin = this.$session.get("usuario");
            this.rol = this.datosLogin.rol.name;
            
            this.urlImg = GetUrlImg.getURL();

            if (this.rol == "root") {
                this.getClientes();
            }

            // this.$apollo.queries.contratosFormatos.skip = false;

            this.filters.tipo = "EMPLEADOS";
            this.filters.sistema    = 'nominas';
            this.filters.estatus_empleado = true;
            this.filters.es_otro_documento = false
            if (this.rol == "admin") {
                this.filters.cliente_id = this.datosLogin.cliente_id;
                
            }

            if (this.rol == "admin-empresa") {
                this.filters.cliente_id = this.datosLogin.cliente_id;
                this.filters.empresa_id = this.datosLogin.empresa_id;
            }

            if(this.rol == "admin-sucursal"){
                Notify.ErrorToast("Tu rol no tiene acceso a este módulo");
            }

            //this.getTiposContratosSAT();
        },

        /*
        Entrada: isSelected: Boleano pasando el valor del check todos de la tabla.
                 items: Array de ids de empleados para llenar los checbox seleccionado
        Salida:
        Descripción: Se asigna eñ valor del check masivo para validar en
                     este modulo y nos da los items de los empleados si 
                     o no seleccionados.
        */
        async selectAll(isSelected, items) {
            this.checkAll = isSelected;
            this.selected_general = items;

            if(!isSelected){
                this.unselected_general = [];
            }
        },

        /*
        Entrada: id: id del empledo seleccionado
        Salida:
        Descripción: Agrega o quita del array de empleados seleccionados
                     segun el id mandado y agrega a los no seeccionado en el 
                     caso del check masivo sea verdadero.
        */
        selectEmployee(id){
            if(this.selected_general.includes(id)){
                let index = this.selected_general.indexOf(id);
                this.selected_general.splice(index, 1);
            }else{
               this.selected_general.push(id); 
            }

            if(this.checkAll){
                if(this.unselected_general.includes(id)){
                    let index = this.unselected_general.indexOf(id);
                    this.unselected_general.splice(index, 1);
                }else{
                    this.unselected_general.push(id); 
                }
            }

            //console.log("sel",this.selected_general)
            //console.log("unsel",this.unselected_general)
        },

        /*
        Entrada:
        Salida:
        Descripción: Abre el explorador de archivos del 
                     navegador para seleccionar un archivo 
                     y subirlo como contrato firmado.
        */
        open(){
            document.getElementById("fileDocument").click();
        },

        /*
        Entrada:item Objeto contrato {}
        Salida:
        Descripción: Abre el modal visualizador de pdf y 
                     hace la peticion al api para traer 
                     el contrato y ser visualizado.
        */
        abrirModalPDF(item){
            let self = this;
            let url = "contratos/descargar";

            this.titulo_modal = "Vista previa";
            this.dialog_pdf = true;

            const axios = require("axios");

            let parametros = { url_archivo: item.url_archivo,contrato_empleado_id:item.id,public_url:this.urlImg.slice(0, -1) };
            try {
                /*
                Entrada:parametros Objeto {url_archivo,contrato_empleado_id}
                Salida: blob del contrato en pdf
                Descripción: API que puede recibir url_archivo null para 
                             traer el contrato del sistema o un string  
                             para traer el archivo firmado.
                */
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then(function(response) {
                    var fileURL = URL.createObjectURL(response.data);
                    $('#myIframe').attr('src', fileURL); 
                    $('iframe').on("load", function () {
                        $(this).contents().find('img').css({width: '100%'});
                    });
                    self.procesoFinalizado  = true;
                });
            } catch (error) {
                console.log(error);
            }
        },

        /*
        Entrada:item Objeto contrato {}
        Salida:
        Descripción: Abre el modal de renovación y asigna 
                     parametros para la renovación.
        */
        async abrirModalRenovar(item = null, tipoAccion = null) {
            await this.resetValues()
            this.tipoAccion = tipoAccion;
            this.renovacion.tipo_contrato_id = null;
            this.renovacion.dias = null;

            if(tipoAccion == 'renovarMasivo' ||  tipoAccion == 'renovarIndividual'){
                this.titulo_modal = 'Renovar contrato';
                if(tipoAccion == 'renovarIndividual'){
                    this.selected_general = []
                }

            }
            else if (tipoAccion == 'regenerarMasivo' ||  tipoAccion == 'regenerarIndividual'){
                this.titulo_modal = 'Regenerar contrato';
                if(tipoAccion == 'regenerarIndividual'){
                    this.selected_general = []
                }
            }


            if(item){
                this.renovacion.contrato_empleado_id = item.id;
                this.renovacion.registro_patronal_id = item.registro_patronal_id;
            }else{
                this.renovacion.contrato_empleado_id = null;
                if(tipoAccion == 'regenerarIndividual'){
                    this.renovacion.registro_patronal_id = null;
                }
            }

            this.dialogRenovar = true;
            this.getContratosFormatos()
        },

        /*
        Entrada:item Objeto contrato {}
        Salida:
        Descripción: Abre el modal para subir contratos firmados y 
                     asigna parametros para la subida del documento.
        */
        abrirModalCargaDocumento(item){
            this.dialogCargaDocumento = true;
            this.titulo_modal = 'Cargar contrato firmado';
            
            this.archivo.cliente_id = item.data.cliente_id;
            this.archivo.empresa_id = item.data.empresa_id;
            this.archivo.sucursal_id = item.data.sucursal_id;
            this.archivo.departamento_id = item.data.departamento_id;
            this.archivo.empleado_id = item.data.empleado_id;
            this.archivo.contrato_empleado_id = item.id;  
        },
        
        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de tipos contratos sat para 
                     llenar el select de tipo contrato.
        */
        getTiposContratosSAT(){
            let parametros = { activo:true, paginate:false, order:"asc" }
            apiTipoContratoSAT.find(parametros).then((response) => {
                let format = response.data;
                if(response.data.length != 0){
                    format.forEach( item => {
                        item.claveDescripcion = item.clave + ' - ' + item.descripcion
                        this.tipoContratos.push(item);
                    })
                }
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los Tipos de contratos.");
            });
        },

        async getContratosFormatos() {
            let parametros = {
                activo: true,
                paginate: false,
                order: "asc",
                registro_patronal_id: this.renovacion.registro_patronal_id,
                tipo: "EMPLEADOS",
                sistema: 'nominas',
                es_otro_documento: false
            }
            await contratosApi.find(parametros).then((response) => {
                this.tipoContratos = response.data
                this.tipoContratos.map(item => {
                    let rp = item.nombre_registro_patronal !== null ? "-" + item.nombre_registro_patronal : ""
                    item.claveDescripcion = item.clave_formato + "-" + item.nombre + rp
                })
            }).catch((err) => {
                console.log(err)
                Notify.ErrorToast("Ocurrio un error al cargar los tipos de contratos")
            })  
        },
        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de clientes para llenar 
                     el select en el filtrado.
        */
        async getClientes() {
        let param = { activo: true, paginate: false };
        await clientesApi
            .getClientes(param)
            .then(response => {
                this.clientes = response.data;
            })
            .catch(err => {
            console.error(err);
            Notify.ErrorToast("Ocurrió un error al cargar los clientes");
            });
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de Empresas para llenar 
                     el select en el filtrado.
        */
        async cambiaCliente(cliente) {
            if(cliente == null){
                return;
            }

            let param = { activo: true, paginate: false, cliente_id: cliente };
            await empresaApi
                    .getEmpresas(param)
                    .then(response => {
                        this.empresas = response.data;
                })
                .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las empresas.");
                });
        },

        async getRegistrosPatronales(clienteId) {
            this.itemsRegistrosPatronales = null;
            if(clienteId == null){
                return;
            }

            let param = { 
                activo: true, 
                paginate: false, 
                cliente_id: clienteId 
            };
            await apiRegistroPatronal.find(param).then(response => {
                this.itemsRegistrosPatronales = response.data;
            })
            .catch(err => {
                console.error(err);
                Notify.ErrorToast("Ocurrió un error al cargar los registros patronales.");
            });
            
        },

         /*
        Entrada:
        Salida:
        Descripción: Ejectuta API get de Sucursales para llenar
                     el select en el filtrado.
        */
        async cambiaEmpresa(empresa) {
            if(empresa == null){
                return;
            }
            let parametros = { activo: true, paginate: false, empresa_id: empresa };
            await sucursalApi
                .getSucursales(parametros)
                .then(response => {
                    this.sucursales = response.data;
                })
                .catch(err => {
                    console.error(err);
                    Notify.ErrorToast("Ocurrió un error al cargar las sucursales.");
                });
        },

        async loadModalData() {
            await this.resetValues();
            this.accion = 'BUSQUEDA'
            await this.getEstatus();
            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
                this.cliente_value = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            } else {
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
        },

        /*
        Entrada:
        Salida:
        Descripción: Prepara los parametros para el filtrado 
                     de la tabla segun se llenen los inputs 
                     en la bisqueda avanzada.
        */
        async setFilters(){
            let nombre          = this.nombre;
            let cliente         = this.cliente_value;
            let empresa         = this.empresa_value;
            let sucursal        = this.sucursal_value;
            let tipo_contrato   = this.tipo_contrato_value;
            let estatus         = this.contrato_estatus_value; 
            let tipo_empleado   = this.valorTipoEmpleado;
            let registroPatronal = this.registro_patronal_value;

            if(this.valorTipoEmpleado == true) {
                this.disabledCheck = false;
                this.$refs.tablaContratos.unselectAll()
            }
            else{
                this.disabledCheck = true;
                this.$refs.tablaContratos.unselectAll();
            }
            

            if (this.rol == "admin") {
                cliente = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                empresa = this.datosLogin.empresa_id;
            } else if (this.rol == "admin-sucursal") {
                sucursal = this.datosLogin.sucursal_id;
            }

            let filterParams = {
                nombre              : nombre,
                cliente_id          : cliente,
                empresa_id          : empresa,
                sucursal_id         : sucursal,
                tipo_contrato_id    : tipo_contrato,
                clave_estatus       : estatus,
                estatus_empleado    : tipo_empleado,
                registro_patronal_id: registroPatronal,
                es_otro_documento: false
            };

            let defaultParams = {
                activo: true,
                paginate: true,
                include_padres: true,
                rol_name: this.datosLogin.rol.name,
                usuario_id: this.datosLogin.id,
                tipo: this.filters.tipo,
                sistema:'nominas',
                es_otro_documento: false
            };

            if (filterParams != null) {
                Object.assign(defaultParams, filterParams);
            }

            this.renovacion.registro_patronal_id = registroPatronal

            this.filters = defaultParams;

            this.resetValues();
        },
        
        /*
        Entrada:
        Salida:
        Descripción: Resetea las variables para cerrar un 
                     modal y dejarlo en estado inicial.
        */
        cerrarModal() {
            this.isSaving = false;
            this.dialog_pdf = false; 
            this.dialogRenovar = false;
            this.procesoFinalizado = false;
            this.dialogCargaDocumento = false;
            this.dialogReporte = false;
           
            $('#myIframe').attr('src', "");

            this.resetValues();
        },

        /*
        Entrada:
        Salida:
        Descripción: Resetea las variables del modulo 
                     segun el rol para dejarlo en estado inicial.
        */
        async resetValues() {
            if(this.$refs.form){
                this.$nextTick(() => {
                    this.$refs.form.reset();
                });
            }

            if(this.$refs.formReporte){
                this.$nextTick(() => {
                    this.$refs.formReporte.reset();
                });
            }
            

            this.$nextTick(() => { 
                if (this.rol == "root") {
                    this.cliente_value = null;
                    this.empresa_value = null;
                    this.sucursal_value = null;
                    this.empresas = [];
                    this.sucursales = [];
                }
    
                if (this.rol == "admin") {
                    this.empresa_value = null;
                    this.sucursal_value = null;
                    this.sucursales = [];
                }

                if (this.rol == "admin-empresa") {
                    this.sucursal_value = null;
                }

                this.nombre = null;
                this.tipo_contrato_value = null;
                this.contrato_estatus_value = null;
                this.renovacion.contrato_empleado_id = null;
                //this.renovacion.registro_patronal_id = null;
                this.tipoContratos = [];
                this.valorEstatus = null;
                this.valorTipoEmpleado = true;
                this.fechaInicio         = null;
                this.fechaFin            = null;
                this.rangoFechaFin_Inicio= null;
                this.rangoFechaFin_Fin   = null;
                this.tipoReporte         = 'HISTORICO';

                this.registro_patronal_value = null;

                this.valorTipoEmpleadoReporte = "todos";
               
                this.archivo = {
                    archivo                 : [],
                    cliente_id              : null,
                    empresa_id              : null,
                    sucursal_id             : null,
                    departamento_id         : null,
                    empleado_id             : null,
                    documento_id            : null,
                    url                     : null,
                    extension               : null,
                    nombre                  : null,
                    contrato_empleado_id    : null
                }
            });

        },

        //FUNCIONES ESPECIFICAS
        
        /*
        Entrada:
        Salida:
        Descripción: Ejecuta el API renovarContrato() enviando los 
                     parametros tomados del contrato seleccionado
                     y actualiza la tabla para mostrar los cambios.
        */
        renovarContrato(){

            let params = {"contrato_empleado_id": this.renovacion.contrato_empleado_id,
                          "tipo_contrato_id": this.renovacion.tipo_contrato_id,
                          "dias": this.renovacion.dias};
            
            this.isSaving = true;

            contratosApi
                .renovarContrato(params)
                .then((response) => {
                    this.$refs.tablaContratos.getData();
                    this.cerrarModal();
                    this.renovavion.registro_patronal_id = null
                    Notify.Success("El contrato se renovó satisfactoriamente.");
                })
                .catch((err) => {
                    this.isSaving = false;
                    gFunctions.errorParse(err.response.data.error);
                });
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejecuta el API renovarContratoMasivo() enviando los 
                     parametros masivos de renovacion seleccionados
                     y actualiza la tabla para mostrar los cambios.
        */
        renovarContratoMasivo(){

            let params = {  "todos"               : this.checkAll,
                            "paginate"            : false,
                            "deseleccionados"     : this.unselected_general,
                            "seleccionados"       : this.selected_general,
                            "dias"                : this.renovacion.dias,
                            "tipo_contrato_id"    : this.renovacion.tipo_contrato_id,
                            "nombre"              : this.filters.nombre,
                            "cliente_id"          : this.filters.cliente_id,
                            "empresa_id"          : this.filters.empresa_id,
                            "sucursal_id"         : this.filters.sucursal_id,
                            "tipo"                : this.filters.tipo,
                            "sistema"             : 'nominas'
                          }
            
            this.isSaving = true;

            contratosApi
                .renovarContratoMasivo(params)
                .then((response) => {
                    if(response.status == 200){
                        this.renovacion.registro_patronal_id = null
                        Notify.Success("Los contratos se renovarón satisfactoriamente.");
                    }
                    else if( response.status== 202){
                        this.dialogErrores = true;
                        this.erroresModal  = response.data.message;
                        this.isLoadingError = false;
                    }
                    this.$refs.tablaContratos.getData();
                    this.cerrarModal();
                    
                })
                .catch((err) => {
                    this.isSaving = false;
                    gFunctions.errorParse(err.response.data.error);
                });
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejecuta el API regenerarContratoMasivo() enviando los 
                     parametros masivos de renovacion seleccionados
                     y actualiza la tabla para mostrar los cambios.
        */
        regenerarContratoMasivo(){
            let params = {  "todos"               : this.checkAll,
                            "paginate"            : false,
                            "deseleccionados"     : this.unselected_general,
                            "seleccionados"       : this.selected_general,
                            "nombre"              : this.filters.nombre,
                            "cliente_id"          : this.filters.cliente_id,
                            "empresa_id"          : this.filters.empresa_id,
                            "sucursal_id"         : this.filters.sucursal_id,
                            "tipo"                : this.filters.tipo,
                            "sistema"             : 'nominas',
                          }

            Notify.Alert(
                "Regenerar contratos",
                "¿Estás seguro que quieres regenerar los contratos seleccionados?",
                "Regenerar",
            ()=>{
                this.overlay = true;
                this.fraseOverlay = "Regenerando contratos.";

                contratosApi.regenerarContratoMasivo(params).then((response) => {
                    if(response.status == 200){
                        Notify.Success("Los contratos se regenerarón satisfactoriamente.");
                    }
                    else if( response.status== 202){
                        this.dialogErrores = true;
                        this.erroresModal  = response.data.message;
                        this.isLoadingError = false;
                    }
                    this.$refs.tablaContratos.getData();
                    this.cerrarModal();
                    
                    this.overlay = false;
                })
                .catch((err) => {
                    this.overlay = false;
                    gFunctions.errorParse(err.response.data.error);
                });
            })
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejecuta el API regenerarContrato() enviando los 
                     parametros tomados del contrato seleccionado
                     y actualiza la tabla para mostrar los cambios.
        */
        regenerarContrato(data){
            let params = {"contrato_empleado_id": data.id};

            //let params = {"contrato_empleado_id": this.renovacion.contrato_empleado_id,
            //              "tipo_contrato_id": this.renovacion.tipo_contrato_id,
            //              "dias": this.renovacion.dias};

            Notify.Alert(
                "Regenerar contrato",
                "¿Estás seguro que quieres regenerar el contrato?",
                "Regenerar",
            ()=>{
                this.overlay = true;
                this.fraseOverlay = "Regenerando contrato.";
                contratosApi.regenerarContrato(params).then((response) => {
                    this.$refs.tablaContratos.getData();
                    this.cerrarModal();
                    Notify.Success("El contrato se regenero satisfactoriamente.");
                    this.overlay = false;
                })
                .catch((err) => {
                    this.overlay = false;
                    gFunctions.errorParse(err.response.data.error);
                });

            })
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejecuta el API delete() enviando el id
                     tomado del contrato seleccionado
                     y actualiza la tabla para mostrar los cambios.
        */
        eliminar(id){
            let self = this;

            Notify.Alert("¿Seguro eliminar este contrato?","El contrato no se podrá recuperar","Continuar",()=>{contratosApi
                .delete(id)
                .then((response) => {
                    self.$refs.tablaContratos.getData();
                    Notify.Success("El contrato se elimino satisfactoriamente.");
                })
                .catch((err) => {
                    gFunctions.errorParse(err.response.data.error);
                });})
            
        },
        eliminarDocumento(id){
            let self = this;
            let param = {
                contrato_empleado_id : id,
                tipo : "delete",
            }

            Notify.Alert("¿Seguro eliminar el documento de este registro?","El documento de este contrato no se podrá recuperar","Continuar",()=>{
                contratosApi.deleteDocumento(param).then((response) => {
                    self.$refs.tablaContratos.getData();
                    Notify.Success("El contrato se elimino satisfactoriamente.");
                })
                .catch((err) => {
                    gFunctions.errorParse(err.response.data.error);
                });
            })
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejecuta el api contratos/descargar para traer el 
                     fromato del contrato para poder descargarlo 
                     y frimarlo fisicamente.
        */
        async descargar(itemDescarga = null) {
            const {data:item, nombre_completo} = itemDescarga;
            this.overlay = true;
            this.fraseOverlay = "Descargando.";
            let self = this;
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let url = "contratos/descargar";
            let parametros = { url_archivo: item.url_archivo,contrato_empleado_id:item.id,public_url:this.urlImg.slice(0, -1) };


            var nombre_archivo = "";
            let nombreArchivo = "";
            let archivo_extension = "";
            let random = this.getRandom()
            /* if(item.url_archivo) {
                nombreArchivo = item.url_archivo.split("/");
                archivo_extension = nombreArchivo[nombreArchivo.length - 1].split(".")
                nombre_archivo = nombre_completo + "_" + this.getRandom() + "." + archivo_extension[archivo_extension.length - 1];
            }
            else{
                nombre_archivo = nombre_completo + "_" + this.getRandom() + ".pdf";
            } */
            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                }).then(function(response) {
                    self.overlay = false;
                    let extensionArchivo = response.headers['content-type'].split('/')
                    extensionArchivo = extensionArchivo[1]
                    nombreArchivo = `${nombre_completo}_${random}.${extensionArchivo}`
                    console.log(nombreArchivo)
                    FileDownload(response.data, nombreArchivo);
                    
                });
            } catch (error) {
                console.log(error);
                self.overlay = false;
            }
        },

        getRandom(min=100, max=1000) {
            return Math.floor(Math.random() * (max - min)) + min;
        },

        /*
        Entrada:
        Salida:
        Descripción: Ejecuta el api contratos/descargar-masivo para traer el 
                     fromato del contrato para poder descargarlo 
                     y frimarlo fisicamente.
        */
        async descargarMasivo(item = null) {
            this.overlay = true;
            this.fraseOverlay = "Descargando.";
            let self = this;
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let url = "contratos/descargar-masivo";
            let parametros = {  "todos"               : this.checkAll,
                                "paginate"            : false,
                                "deseleccionados"     : this.unselected_general,
                                "seleccionados"       : this.selected_general,
                                "nombre"              : this.filters.nombre,
                                "cliente_id"          : this.filters.cliente_id,
                                "empresa_id"          : this.filters.empresa_id,
                                "sucursal_id"         : this.filters.sucursal_id,
                                "tipo"                : this.filters.tipo,
                                "sistema"             : 'nominas',
                                "accion"              : 'contratos'
                            };

            var nombre_archivo = "Contratos.zip";

            if(item){
                nombre_archivo = item.nombre + ".zip";
            }

            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros
                }).then(function(response) {
                    if(response.headers['content-type'] == 'fallo')
                        Notify.ErrorToast("No se encontraron todo los archivos solicitados.");
                    
                    self.overlay = false;
                    FileDownload(response.data, nombre_archivo);
                    
                })
                .catch((error) => {
                    self.overlay = false;
                    Notify.ErrorToast("No se encontro el archivo solicitado.");
                });
            } catch (error) {
                console.log(error);
                self.overlay = false;
            }
        },

        /*
        Entrada:
        Salida:
        Descripción: Prepara el archivo seleccionado en el modal de subir archivo 
                     para ser enviado ejectutando el API addArchivo() una vez 
                     terminado la carga del archivo se actualiza la url del empleado
                     ejecutando el API updateArchivo() y actualiza la tabla 
                     para mostrar los cambios.
        */
        guardarDocumento() {
            let self = this;
            if (this.archivo.archivo.length == 0) {
                Notify.Warning("ADVERTENCIA", "No se ha seleccionado un archivo");
                return;
            }

            this.isSaving = true;
            let formData = new FormData();
            formData.append("archivo", this.archivo.archivo[0]);
            formData.append("contrato_empleado_id",self.archivo.contrato_empleado_id);
            formData.append("tipo","update");

            contratosApi.updateDocumento(formData).then((response) => {
                self.$refs.tablaContratos.getData();
                this.cerrarModal();
                Notify.Success("El archivo se guardó satisfactoriamente.");
            })
            .catch((err) => {
                gFunctions.errorParse(err.response.data.error);
            });
        },

        getContratosFormatosBusqueda(){
            let parametros = { 
                activo                  : true, 
                paginate                : false, 
                order                   : "asc",
                cliente_id              : this.cliente_value,
                tipo                    : this.filters.tipo, 
                sistema                 : 'nominas'
            }
            contratosApi.find(parametros).then((response) => {
                this.tipoContratosBusqueda = response.data;
                this.tipoContratosBusqueda.map(item => {
                    let rp = item.nombre_registro_patronal !== null ? " - "+item.nombre_registro_patronal:""
                    item.claveDescripcion = item.clave_formato+" - "+item.nombre+rp;
                });
            })
            .catch(err => {
                console.log(err);
                Notify.ErrorToast("Ocurrió un error al cargar los tipos de contratos.");
            });
        },
        redireccionar(){
            this.$router.push('/dashboard');
        },

        async abrirModalReporte() {
            
            await this.resetValues();

            this.accion = 'REPORTE'
            await this.getEstatus()
            
            if (this.rol == "root") {
                this.getClientes();
            } else if (this.rol == "admin") {
                this.cambiaCliente(this.datosLogin.cliente_id);
                this.cliente_value = this.datosLogin.cliente_id;
            } else if (this.rol == "admin-empresa") {
                this.cambiaEmpresa(this.datosLogin.empresa_id);
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            } else {
                this.sucursal_value = this.datosLogin.sucursal_id;
                this.cliente_value = this.datosLogin.cliente_id;
                this.empresa_value = this.datosLogin.empresa_id;
            }
            this.titulo_modal = "Reporte de contratos"
            this.dialogReporte = true;
        },

        async descargarReporte() {
            this.overlay = true;
            this.fraseOverlay = "Descargando.";
            let self = this;
            const FileDownload = require("js-file-download");
            const axios = require("axios");
            let url = "reportes/reporte-contratos";

            let estatusEmpleado = null;

            if(this.valorTipoEmpleadoReporte == "todos"){
                estatusEmpleado = null;
            }
            else if(this.valorTipoEmpleadoReporte == "activos") {
                estatusEmpleado = true;
            }
            else if(this.valorTipoEmpleadoReporte == "inactivos") {
                estatusEmpleado = false;
            }

            let parametros = {
                cliente_id              : null,
                empresa_id              : null,
                sucursal_id             : null,
                clave_estatus           : this.valorEstatus,
                estatus_empleado        : estatusEmpleado,
                fecha_inicio            : this.fechaInicio,
                fecha_fin               : this.fechaFin,
                fecha_inicio_final      : this.rangoFechaFin_Inicio,
                fecha_fin_final         : this.rangoFechaFin_Fin,
                tipo_reporte            : this.tipoReporte,
                registro_patronal_id    : this.registro_patronal_value,
                tipo                    : 'xlsx',
                es_otro_documento       : false
            }

            if(this.rol == 'root'){
                parametros.cliente_id = this.cliente_value;
                parametros.empresa_id = this.empresa_value;
                parametros.sucursal_id = this.sucursal_value;
            }
            else if( this.rol == 'admin'){
                parametros.cliente_id = this.datosLogin.cliente_id;
                parametros.empresa_id = this.empresa_value;
                parametros.sucursal_id = this.sucursal_value;
            }
            else if(this.rol == 'admin-empresa'){
                parametros.cliente_id = this.datosLogin.cliente_id;
                parametros.empresa_id = this.datosLogin.empresa_id;
                parametros.sucursal_id = this.sucursal_value;
            }
            else{
                parametros.cliente_id = this.datosLogin.cliente_id;
                parametros.empresa_id = this.datosLogin.empresa_id;
                parametros.sucursal_id = this.datosLogin.sucursal_id;
            }

            let nombre_archivo = "Reporte_de_empleados_contratos.xlsx";

            try {
                axios({
                    method: "post",
                    url: url,
                    responseType: "blob",
                    data: parametros,
                })
                .then((response) => {
                    self.overlay = false;
                    FileDownload(response.data, nombre_archivo);
                    self.cerrarModal();
                })
                .catch(async error =>{
                    this.overlay = false;
                    this.cerrarModal();
                    let errorString = error.response.data;
                    if (
                        error.request.responseType === 'blob' &&
                        error.response.data instanceof Blob &&
                        error.response.data.type &&
                        error.response.data.type.toLowerCase().indexOf('json') != -1
                    ){
                        errorString = JSON.parse(await error.response.data.text());
                        console.log(errorString)

                        if(typeof errorString.error === 'string'){
                            Notify.ErrorToast(errorString.error);
                        }else{
                            let error = Object.values(errorString.error);
                            let leyenda = ``;

                            for (var i = 0; i < error.length; i++) {
                                leyenda+= `* ` + error[i] + `\n`;
                            }
                            Notify.ErrorToast(leyenda);
                        }
                    }
                    else{
                        Notify.ErrorToast("Error");
                    }
                });
                
            } catch (error) {
                console.log(error);
                self.overlay = false;
            }
        },
        async getEstatus() {
            let param = { activo: true, paginate: false, tipo: this.accion };
            await contratosApi.findEstatus(param).then((response) => {
                this.contratos_estatus = response
            })
            .catch((error) => {
                console.error(error);
                console.log("Ocurrió un error al cargar los estatus.");
            });
        },

        cerrarModalErrores(){
            this.dialogErrores = false;
            this.erroresModal = [];
        },
    },
    apollo: {
        contratosFormatos: {
            query       : queries.contratosFormatosTableQuery,
            fetchPolicy : 'network-only',
            variables() {
                return {
                    whereConditions : {
                                    OR: [
                                       // {column: "REGISTRO_PATRONAL_ID", operator:"IS_NULL"},
                                    {
                                        AND:[
                                            {column:"REGISTRO_PATRONAL_ID",value:this.renovacion.registro_patronal_id},
                                            {column:"TIPO",value:"EMPLEADOS"},{column: "ES_OTRO_DOCUMENTO", value: false}
                                        ]
                                    },]},
                    numberItems : 10000,
                    numberPage  : 1,
                    fieldOrder  : 'consecutivo',
                    Order       : 'ASC',
                }
            },
            skip() {
                return true;
            },
            result({loading, error}) {
             /*    this.tipoContratos = JSON.parse(JSON.stringify(this.contratosFormatos.data));

                this.tipoContratos.map(item => {
                    let rp = item.registroPatronal !== null ? " - "+item.registroPatronal.nombre:""
                    item.claveDescripcion = item.tipoContrato.clave+" - "+item.nombre+rp;
                }); */
            },
        },
    },
    //CREATED Se ejecutan las funciones o intrucciones iniciales para el funcionamiento del modulo.
    created() {
        this.datosLogin = this.$session.get("usuario");
        this.rol = this.datosLogin.rol.name;
        this.init();
    },
    mounted(){

    },
    updated() {  
        document.querySelector('#tableScroll tbody').style.minWidth   = "1250px";      
        document.querySelector('#tableScroll thead tr th:nth-child(1)').style.paddingRight   = "4px";
        document.querySelector('#tableScroll thead tr th:nth-child(1)').style.width   = "80px";
        document.querySelector('#tableScroll thead tr th:nth-child(2)').style.paddingLeft   = "16px";
        document.querySelector('#tableScroll thead tr th:nth-child(6)').style.paddingRight   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(6)').style.paddingLeft   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(7)').style.paddingLeft   = "8px";
        document.querySelector('#tableScroll thead tr th:nth-child(7)').style.width   = "80px";
    }
    
}
</script>


<style scoped>
    #padre {
        padding-left: 40%;
        height: 200px;
    }
    #loading {
        display: table-cell;
        vertical-align: middle;
    }
    #contenedor {
        
    }
    .invertirColor {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-stroke-color: rgba(0,0,0,0.54);
    }
    .tamIcono {
        height: 24px !important;
        align-self: center !important;
    }
    .iconoDelgado {
        color: white;
        -webkit-text-stroke-width: 1px;
        -webkit-text-fill-color: rgba(0,0,0,0.54);
    }
    .botonModal.theme--light.v-btn:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined){
        display: none;
    }
    .cursor-pointer{
      cursor:pointer;
    }
    .radio_group_01 div{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.2px;
        color: #828282;
    }

    iframe{
        border:none;
    }

    div.btnAccionesItem {
        width: 100%;
        cursor: pointer;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.btnAccionesItem span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }
    div.btnAccionesItem:hover {
        background: #F6F6F8;
    }
    .deshabilitado{
        background:#fcf6f6;
        cursor:not-allowed;

        width: 100%;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.deshabilitado:hover{
        background:#f2f2f2;
        cursor:not-allowed;

        width: 100%;
        outline: 0;
        padding-left: 30px;
        padding-right: 30px;
    }
    div.deshabilitado span {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 18px;
        text-align: left;
        color: #828282;
    }


    #btnModalErrores{
        display: none;
    }

    .titleModalErrores{
        font-family: Montserrat;
        font-style: normal;
        font-weight: 500;
        font-size: 22px;
        line-height: 18px;
        letter-spacing: 0.1px;
        color: #444975;
    }
    .emptyTableErrores{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 22px;
        text-align: center !important;
        color: #c5c5c5;
    }
    .tbl-errores {
        border-collapse: collapse;
        width: 100%;
        margin-top: 15px;
        /* white-space: nowrap; */
        overflow-x: scroll;
        overflow-y: scroll;
    }

    .tbl-errores .tbl-header {
        background: #F6F6F6;
        border-radius: 10px;
        height: 58px;
    }
    .errorth {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #828282;
        line-height: 20px;
        padding: 8px;
        text-align: left !important;
    }
    .errortd {
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        color: #96999A;
        line-height: 15px;
        padding: 8px;
        text-align: left !important;
    }
    .textoTablaError01{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: #1E2245;
        text-align: left !important;
    }
    .textoTablaError02{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 15px;
        color: #828282;
        text-align: left !important;
    }
    .textoTablaError03{
        font-family: 'Montserrat';
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #EB5757;
        line-height: 17px;
        text-align: left !important;
    }
    .trDivider{
        border-bottom-style: solid; 
        border-bottom-width: 1px;
        border-bottom-color: #C4C4C4;
    }
</style>
